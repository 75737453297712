const FB_EVENT_ADD_TO_CART = 'AddToCart';
const FB_EVENT_VIEW_ITEM = 'ViewContent';
const FB_EVENT_BEGIN_CHECKOUT = 'InitiateCheckout';
const FB_EVENT_ADD_PAYMENT_INFO = 'AddPaymentInfo';

const fbTrackEvent = function (eventName, eventData, user = null) {
  if (process.client && typeof window !== 'undefined' && typeof window.fbq === 'function') {
    try {
      let userData = {};
      if (user) {
        userData = getMappedUserData(user);
      }

      window.fbq('track', eventName, eventData, userData);
    } catch (error) {
      console.error("Error sending to Facebook Pixel: " + error);
    }
  }
};

const fbTrackAddToCart = function (product, quantity, user = null) {
  if (quantity === 0) {
    return;
  }

  let price = getPriceOfProduct(product);
  return fbTrackEvent(FB_EVENT_ADD_TO_CART, {
    currency: "EUR",
    value: convertCentsToEuro(price * quantity),
    contents: [getMappedProductObject(product, quantity)],
    content_type: 'product'
  },user);
};

const fbTrackViewItem = function (product, user = null) {
  return fbTrackEvent(FB_EVENT_VIEW_ITEM, {
    contents: [getMappedProductObject(product, 1)],
    content_type: 'product'
  }, user);
};

const fbTrackBeginCheckout = function (items, totalAmount, user = null) {
  return fbTrackEvent(FB_EVENT_BEGIN_CHECKOUT, {
    currency: "EUR",
    value: convertCentsToEuro(totalAmount),
    contents: mapCartItems(items),
    content_type: 'product'
  }, user);
};

const fbTrackAddPaymentInfo = function (items, totalAmount, paymentType, user = null) {
  return fbTrackEvent(FB_EVENT_ADD_PAYMENT_INFO, {
    currency: "EUR",
    value: convertCentsToEuro(totalAmount),
    contents: mapCartItems(items),
    content_type: 'product',
    payment_method: paymentType
  }, user);
};

const getMappedProductObject = function (product, quantity) {
  let price = getPriceOfProduct(product);

  if (product.campaigns?.discountedPrice) {
    price = product.campaigns.discountedPrice;
  }

  return {
    id: product.id,
    name: product.name,
    brand: product.brand?.name,
    price: convertCentsToEuro(price),
    quantity: quantity
  };
};
const hashData = async (data) => {
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
};

const getMappedUserData = function (user) {

  return {
    em: user.email ? hashData(user.email.trim().toLowerCase()).toString() : null,
    ph: user.phone ? hashData(user.phone.trim().replace(/\D/g, '')).toString() : null,
    external_id: user.memberId
  }
}

const mapCartItems = function (cartItems) {
  return cartItems.map(item => ({
    id: item.id,
    name: item.name,
    quantity: item.quantity,
    price: convertCentsToEuro(getPriceOfProduct(item))
  }));
};

const getPriceOfProduct = function (product) {
  return product.sellingData?.price ?? product.price?.amount ?? 0;
};

const convertCentsToEuro = function (value) {
  return Number.parseFloat((value / 100).toFixed(2));
};

export {
  fbTrackAddToCart,
  fbTrackViewItem,
  fbTrackBeginCheckout,
  fbTrackAddPaymentInfo
};
