
export default {
  name: "StatusBadge",
  props: {
    color: {
      type: String,
      validator: val => [
        "red",
        "dark-red",
        "amber",
        "violet",
        "sky",
        "luxcaddy",
        "pink",
        "gray"
      ].includes(val),
    },
    xsmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    }
  },
  computed: {
    getClassesByColor() {
      switch (this.color) {
        case "red":
          return "bg-red-500 text-white";
        case "dark-red":
          return "bg-red-700 text-red-200";
        case "amber":
          return "bg-amber-400 text-black";
        case "violet":
          return "bg-violet-400 text-white";
        case "sky":
          return "bg-sky-500 text-white";
        case "luxcaddy":
          return "bg-luxcaddy-500 text-white";
        case "pink":
          return "bg-pink-500 text-white";
        case "gray":
          return "bg-gray-500 text-white";
      }
    },
    getPadding() {
      if(this.small || this.xsmall) {
        return "px-3 py-1";
      }
      return "text-sm md:text-md px-2 py-1 md:px-4 md:py-2";
    },
    getTextSize() {
      if(this.xsmall) {
        return "text-xs";
      }
      if(this.small) {
        return "text-sm";
      }
      return "text-lg";
    }
  },
}
