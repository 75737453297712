/**
 * Vuex Store for Cart items.
 * This store only applies to logged in users.
 *
 * For the guest store, see guestCart.js
 */

import { findByKey } from "vuex-intern";
import Vue from "vue";
import {trackAddToCart, trackCartCleared, trackRemoveFromCart} from "@/helpers/gtagHelper.js";
import {fbTrackAddToCart} from "@/helpers/fbqHelper.js";

export const state = () => ({
  items: [],
  localItems: [],
  itemCount: 0,
  totals: null,
  hasError: false,
  cartLoading: false,
  isExcluded: false,
  packagingGroups: [],
  loadedCampaigns: [],

  notifications: []
})

export const mutations = {
  setCartData(state, { items, itemCount, totals, hasError, packagingGroups, loadedCampaigns, promoCode, notifications }) {
    state.items = items;
    state.localItems = items.map(i => ({id: i.id, quantity: i.quantity}))
    state.itemCount = itemCount;
    state.totals = totals;
    state.hasError = hasError;
    state.packagingGroups = packagingGroups;
    if (loadedCampaigns !== undefined) {
      state.loadedCampaigns = loadedCampaigns;
    }
    Vue.set(state, 'promoCode', promoCode);
    state.notifications = notifications;
  },
  resetCart(state) {
    state.items = [];
    state.localItems = [];
    state.itemCount = 0;
    state.totals = null;
    state.hasError = false;
    state.cartLoading = false;
    state.packagingGroups = [];
    state.promoCode = null;
    state.notifications = [];
    state.defaultSelections = [];
  },
  setCartItemQuantity(state, { item, quantity }) {
    const itemId = item.id;

    const user = this.$auth?.user;
    let itemInCart = state.localItems.find(i => i.id === itemId);
    if (!itemInCart) {
      // Add item to local cart
      state.localItems.push({id: itemId , quantity: quantity});
      trackAddToCart(item, quantity);
      fbTrackAddToCart(item, quantity, user);
    } else {
      if (quantity === 0) {
        // Remove item from cart
        state.localItems = state.localItems.filter(i => i.id !== itemId);
        trackRemoveFromCart(item, 999);
      } else {
        const oldQty = itemInCart.quantity;

        if(oldQty < quantity) {
          trackAddToCart(item, quantity - oldQty);
          fbTrackAddToCart(item, quantity, user);
        } else {
          trackRemoveFromCart(item, oldQty - quantity);
        }

        // Update quantity in cart
        itemInCart.quantity = quantity;
      }
    }
  },

  setCartLoading(state, boolean) {
    state.cartLoading = boolean;
  },

  toggleCampaignExclusion(state, campaignId) {
    let campaign = state.loadedCampaigns.find(c => c.id === campaignId);

    if (campaign) {
      campaign.isExcluded = !campaign.isExcluded;
    }
  },
}


/**
 * Will set the Default Luxcaddy Bag as selected if none selected yet.
 * @param commit
 * @param rootGetters
 */
function applyDefaultLuxcaddyBagIfNoPackagingSelected(commit, rootGetters) {
  if(rootGetters['checkout/getSelectedBags'].length === 0) {
    const luxcaddyBag = rootGetters["cart/getPackagingGroups"].find(group => group.isMandatory && group.onlyOneChoice)?.options[0] || null;

    if(luxcaddyBag) {
      commit('checkout/setSelectedBag', {id: luxcaddyBag.id, quantity: luxcaddyBag.quantity}, { root: true });
    }
  }
}

export const actions = {
  /**
   * Fetches the current cart from the backend.
   *
   * @param commit
   * @param rootGetters
   * @param loadDefaults
   * @param changeLoadingState
   * @returns {Promise<T>}
   */
  fetchCart({ commit, rootGetters }, loadDefaults = false) {
    commit('setCartLoading', true);

    // 1. Time: Apply preselection if coming to Checkout (= loadDefaults true)
    if(loadDefaults) {
      applyDefaultLuxcaddyBagIfNoPackagingSelected(commit, rootGetters);
    }

    return this.$cartRepository.fetchCart(
      rootGetters['checkout/getSelectedDeliveryContactId'],
      rootGetters['checkout/getSelectedTimeslotId'],
      rootGetters['checkout/getFilteredSelectedBags'],
      rootGetters['cart/getExcludedCampaigns'],
      rootGetters['checkout/getPromoCode'],
      loadDefaults,
      rootGetters['checkout/getSelectedPaymentMethodToSend']
    ).then((res) => {
      commit('setCartData', {
        items: res.data.items,
        itemCount: res.data.itemCount,
        totals: res.data.total,
        hasError: res.data.hasError,
        packagingGroups: res.data.packages,
        loadedCampaigns: res.data.loadedCampaigns,
        promoCode: res.data.promoCode,
        notifications: res.notifications ?? [],
      });

      // Checkout
      // If defaultData === Array, it means its empty.
      // If it is populated, it is an Object...
      if(!Array.isArray(res.data.defaultData)) {
        commit('checkout/setDefaultSelections', res.data.defaultData, { root: true });

        // 2. Time - if it wasn set already before because we didn't have any information about bags yet:
        // Once bags are loaded, set selection to Luxcaddy Bags if there are no other already selected.
        applyDefaultLuxcaddyBagIfNoPackagingSelected(commit, rootGetters);
      }
    }).finally(() =>  {
      commit('setCartLoading', false);
    });
  },

  /**
   * Adds an item to the cart.
   *
   * @param commit
   * @param dispatch
   * @param getters
   * @param item
   * @param quantity
   * @returns {Promise<T>}
   */
  async setCartItemQuantity({ commit, dispatch, state, rootGetters }, { item, quantity, loadDefaults }) {
    commit('setCartLoading', true);

    const itemId = item.id;
    const quantityBeforeUpdate = state.localItems.find(i => i.id === itemId)?.quantity ?? 0;

    return await this.$cartRepository
      .setItem(itemId, quantity)
      .then(async () => {
        await dispatch('fetchCart', rootGetters["checkout/isInCheckout"]).then(() => $nuxt.$emit('cart_updated'));
      })
      .catch(() => {
        // Reset quantity to how it was before.
        commit('cart/setItemQuantity', {
          itemId: itemId,
          quantity: quantityBeforeUpdate,
        });

      }).finally(() => {
        commit('setCartLoading', false);
      });
  },

  /**
   * Removes all items from the cart.
   * @param dispatch
   * @param commit
   * @param getters
   */
  clearItems({ dispatch, getters, commit }) {
    // Only clear items with type product, otherwise backend throws error.
    const items = getters
      .getItems
      .filter(i => i.type !== undefined && i.type === "Product");

    const mappedItems = items
      .map(i => {
        return {
          productId: i.id,
          quantity: 0
        };
      });

    return this.$cartRepository.setItems(mappedItems).then(() => {
        dispatch('fetchCart');

        // Emit Event (see eventMixin)
        $nuxt.$emit('cart_deleted');
        trackCartCleared(items);
      })
      .then(() => this.$toast.success(this.$i18n.t('cart.clear_cart.cleared')));
  },

};

export const getters = {
  getItems: state => state.items,
  getItemsWithoutFeesAndCampaigns: state => {
    return state.items.filter(item => {
      return item.type !== "Fee" && item.type !== "Gift" && item.type !== "Fixed" && item.type !== "Discount" ;
    })
        // only return if still in local items so that items with local quantity 0 are not displayed anymore
        .filter(i => state.localItems.find(x => i.id === x.id));
  },
  getFees: state => state.items.filter(item => item.type === "Fee"),
  getCampaigns: state => state.items.filter(item => item.type === "Gift" || item.type === "Discount" || item.type === "Fixed"),
  getItemCount: state => state.itemCount,
  getItemByKey: findByKey('items', 'id'),
  getItemQuantityInCart: (state) => (itemId) => {
    return state.localItems.find(i => i.id === itemId)?.quantity ?? 0;
  },

  isCartEmpty: state => !state.items.length,
  getTotals: state => state.totals,
  getTotalAmount: state => state.totals?.amount || 0,

  getHasCartErrors: state => state.hasError,
  getPackagingGroups: state => state.packagingGroups,
  getPackagingGroupByName: findByKey('packagingGroups', 'group'),

  getItemsWithError: state => state.items.filter(i => (i.notifications?.errors || []).length > 0),
  getCartErrors: state => state.notifications?.errors || [],

  getLoadedCampaigns: state => state.loadedCampaigns,
  getLoadedCampaignsWithConfirmation: state => state.loadedCampaigns.filter(c => c.needsConfirmation === true),
  getExcludedCampaignsList: state => state.loadedCampaigns.filter(c => c.isExcluded === true),
  getExcludedCampaigns: state => state.loadedCampaigns.filter(c => c.isExcluded === true).flatMap(c => c.id),


  isPromoCodeApplied: state => state.promoCode !== null && state.promoCode.isValid === true,
  isPromoCodeValid: state => state.promoCode?.isValid  || null,
  getPromoCodeCampaignName: state => state.promoCode?.name  || null,
  getPromoCode: state => state.promoCode?.code || null,
  getPromoCodeIfValid: state => {
    if(state.promoCode === null) {
      return null;
    }
    if(state.promoCode.isValid === false) {
      return null;
    }

    return state.promoCode.code;
  },

  isCartLoading: state => state.cartLoading,
}
