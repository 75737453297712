
import ShopCheckbox from "@/layouts/shop/components/forms/inputs/base/ShopCheckbox.vue";
import QuestionMarkButton from "@/layouts/shop/components/buttons/QuestionMarkButton.vue";
import CheckoutTimeSlotPercentageBar
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeSlotPercentageBar.vue";
import CheckoutTimeslotDiscountCampaign
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeslotDiscountCampaign.vue";
import CheckoutSelectionRadioButton
  from "@/layouts/shop/components/checkout/common/CheckoutSelectionRadioButton.vue";
import StatusBadge from "@/layouts/shop/components/status/StatusBadge.vue";
import configHelper from "@/helpers/configHelper.js";

export default {
  name: 'CheckoutTimeSlot',
  components: {
    StatusBadge,
    CheckoutSelectionRadioButton,
    CheckoutTimeslotDiscountCampaign, CheckoutTimeSlotPercentageBar, QuestionMarkButton, ShopCheckbox},
  props: {
    timeslot: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Boolean,
      required: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isRecommended: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onTimeSlotClick() {
      if (this.isFull) {
        this.$modal.show({
          type: 'danger',
          title: this.$t('checkout.timeslot_full'),
          body: this.$t('checkout.timeslot_full_text'),
          primary: {
            label: 'OK',
            theme: 'danger',
            action: () => this.$emit('disabled-confirm'),
          },
        });
        return false;
      }

      this.$emit('selected');
    },
  },
  computed: {
    getFilledPercentage() {
      if(this.hasOrder) {
        // If the user already has an order in a given timeslot, and its full,
        // it will be displayed as slightly not full.
        return this.timeslot.filledPercentage === 100 && !this.reachedMaxOrders
          ? 99
          : this.timeslot.filledPercentage;
      }

      return this.timeslot.filledPercentage;
    },
    isFull() {
      // Timeslot is already selected in a different order from that user.
      // In this case, we don't display it as full, as it will be grouped, and doesn't count towards the limit.
      if(this.hasOrder) {
        if (this.reachedMaxOrders) {
            return true;
        }
      }

      return this.getFilledPercentage >= 100;
    },

    getClassesByState() {
      if (this.isFull) {
        return 'bg-red-50 text-gray-400 cursor-not-allowed opacity-60';
      }

      return "cursor-pointer bg-white hover:bg-luxcaddy-100 border border-gray-100";
    },

    isUnavailable() {
      return this.timeslot.isUnavailable;
    },


    // Timeslot is already selected in a different order from that user.
    hasOrder() {
      return this.timeslot.hasOrder || false;
    } ,

    reachedMaxOrders() {
      return this.timeslot.countOrders >= configHelper.order.maxPerDayAndDestination;
    }
  }
}
