
import StatusBadge from "@/layouts/shop/components/status/StatusBadge.vue";

export default {
  name: "OrderListItemStatus",
  components: {StatusBadge},
  props: {
    status: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getColorByStatus() {
      switch(this.status) {
        case "initialized":
          return "red";
        case "canceled":
          return "dark-red";
        case "confirmed":
          return "amber";
        case "inFulfilment":
          return "violet";
        case "fulfilmentFinalized":
          return "sky";
        case "delivered":
          return "luxcaddy";
        case "enRoute":
          return "pink";
      }
    },
    getTextByStatus() {
        if(this.$te('account.orders.statuses.' + this.status)){
          return this.$t('account.orders.statuses.' + this.status);
        }
        return 'Unknown status';
    },
  }
}
