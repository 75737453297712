import {findByKey} from "vuex-intern";
import Vue from "vue";
import configHelper from "@/helpers/configHelper";
import dayjs from "dayjs";

const getDefaultState = () => ({
  defaultSelections: {
    cardDetails: null,
    deliveryContactId: null,
    paymentProvider: null,
    unavailableTimeSlots: null,
  },

  checkoutIsLoading: true,

  isInCheckout: false,

  // Delivery contact
  selectedDeliveryContactId: null,
  deliveryContacts: [],

  // Timeslot
  timeslots: null,
  selectedTimeslotId: null,
  unavailableTimeslots: [],
  timeslotIdsWithOrders: [], //timeslots that already have an order from the current user and contact.

  // bags
  bags: [],

  // Payment method
  selectedPaymentMethod: null,

  // Credit Card
  selectedCreditCardDetails: null,
  savedCreditCardDetails: null,

  saveNewCreditCard: true,
  selectedCreditCardAlias: null,

  // Promo Code
  promoCode: null,
  isApplyingPromoCode: false, // Loading indicator
  isRemovingPromoCode: false, // Loading indicator

  // Payment
  paymentInitResponse: null,

  // Confirmation
  showConfirmationModal: false,
  confirmButtonLoading: false,
})

export const state = getDefaultState;

export const mutations = {
  clearStore(state) {
    Object.assign(state, getDefaultState());
  },

  setDefaultSelections(state, defaultSelections) {
    if(defaultSelections.cardDetails) {
      Vue.set(state.defaultSelections, 'cardDetails', defaultSelections.cardDetails);
    }

    if(defaultSelections.deliveryContactId) {
      Vue.set(state.defaultSelections, 'deliveryContactId', defaultSelections.deliveryContactId);

      if(state.isInCheckout && state.selectedDeliveryContactId === null) {
        state.selectedDeliveryContactId = defaultSelections.deliveryContactId;
      }
    }

    if(defaultSelections.paymentProvider) {
      Vue.set(state.defaultSelections, 'paymentProvider', defaultSelections.paymentProvider);
    }

    if(defaultSelections.unavailableTimeSlots) {
      let timeslots = defaultSelections.unavailableTimeSlots;

      // Convert to a better format
      if(timeslots !== null && typeof timeslots === "object") {
        timeslots = Object.keys(timeslots).map(date => {
          return {
            date,
            items: timeslots[date]
          };
        });
      }

      Vue.set(state.defaultSelections, 'unavailableTimeSlots', timeslots);
    }
  },

  /*
    Navigation
   */
  setIsInCheckout(state, bool) {
    state.isInCheckout = bool;
  },
  setCheckoutIsLoading(state, bool) {
    state.checkoutIsLoading = bool;
  },


  setSelectedDeliveryContactId(state, id) {
    state.selectedDeliveryContactId = id;
  },

  setDeliveryContacts(state, deliveryContacts) {
    state.deliveryContacts = deliveryContacts;
  },

  setTimeslots(state, timeslots) {
    state.timeslots = timeslots;
  },

  setSelectedTimeslotId(state, timeslotId) {
    state.selectedTimeslotId = timeslotId;
  },

  setSelectedPaymentMethod(state, method) {
    state.selectedPaymentMethod = method;
  },

  setSelectedCreditCardDetails(state, details) {
    Vue.set(state, 'selectedCreditCardDetails', details);
  },

  setSavedCreditCardDetails(state, creditCardsArray) {
    Vue.set(state, 'savedCreditCardDetails', creditCardsArray);
  },


  setSaveNewCreditCard(state, bool) {
    state.saveNewCreditCard = bool;
  },
  setSelectedCreditCardAlias(state, alias) {
    state.selectedCreditCardAlias = alias;
  },

  /*
    Bags
   */

  setSelectedBag(state, {id, quantity}) {
    state.bags = [{id, quantity}];
  },

  addSelectedBag(state, {id, quantity}) {
    if (this.getters["checkout/getSelectedBagById"](id)) {
      this.commit('checkout/removeSelectedBag', id);
    }

    state.bags.push({id, quantity});
  },

  removeSelectedBag(state, id) {
    state.bags = state.bags.filter(b => b.id !== id);
  },


  setPaymentInitResponse(state, responseData) {
    Vue.set(state, 'paymentInitResponse', responseData);
  },

  /**
   * PROMO CODE
   */
  setPromoCode(state, promoCode) {
    state.promoCode = promoCode;
  },

  setIsApplyingPromoCode(state, bool) {
    state.isApplyingPromoCode = bool;
  },

  setIsRemovingPromoCode(state, bool) {
    state.isRemovingPromoCode = bool;
  },

  setShowConfirmationModal(state, bool) {
    state.showConfirmationModal = bool;
  },

  setConfirmButtonLoading(state, bool) {
    state.confirmButtonLoading = bool;
  },

  setTimeslotIdsWithOrders(state, timeslotIdsWithOrders) {
    state.timeslotIdsWithOrders = timeslotIdsWithOrders;
  }
}

export const getters = {
  isInCheckout: state => state.isInCheckout,
  getCheckoutIsLoading: state => state.checkoutIsLoading,

  getSelectedDeliveryContactId: state => state.selectedDeliveryContactId,
  getDeliveryContacts: state => state.deliveryContacts,
  hasSelectedDeliveryContact: state => state.selectedDeliveryContactId !== null,
  getZoneByDeliveryContact: state => {
    const selectedContactId = state.selectedDeliveryContactId;

    if(selectedContactId === null) {
      return null;
    }

    const selectedContact = state.deliveryContacts.find(contact => contact.id === selectedContactId);

    return selectedContact === undefined ? null : selectedContact.zoneId;
  },

  // Merge timeslots with unavailable ones for selected delivery Contact, if any.
  getTimeslots: state => {
    let timeslots = state.timeslots || [];
    let unavailableTimeslots = state.defaultSelections.unavailableTimeSlots;

    timeslots = timeslots.map(timeslot => {
      let matchingUnavailable = false;
      let isUnavailable = false;

      if(unavailableTimeslots !== null && (Array.isArray(unavailableTimeslots) && unavailableTimeslots.length > 0)) {
        matchingUnavailable = unavailableTimeslots.find(unavailableDate => {
          return dayjs(unavailableDate.date).isSame(dayjs(timeslot.startDate), 'day');
        });

        isUnavailable = matchingUnavailable !== undefined;
      }

      return {
        isUnavailable: isUnavailable,
        unavailableReason: isUnavailable ?  matchingUnavailable.items : null,
        ...timeslot
      }
    });

    return timeslots;
  },
  getTimeslotIdsWithOrders: state => state.timeslotIdsWithOrders,



  getSelectedTimeslotId: state => state.selectedTimeslotId,
  getSelectedTimeslot: state => {
    if(state.selectedTimeslotId === null) {
      return null;
    }

    return state.timeslots.find(ts => ts.id === state.selectedTimeslotId) || null;
  },

  getUnavailableTimeslots: state => state.defaultSelections.unavailableTimeSlots,

  getDefaultPaymentMethod: state => state.defaultSelections.paymentProvider,
  getDefaultCardDetails: state => state.defaultSelections.cardDetails,
  getSelectedPaymentMethod: state => state.selectedPaymentMethod,

  // will be sent to backend when confirming order (only new order)
  getSelectedPaymentMethodToSend: (state, getters, rootState, rootGetters) => {
    const walletAmount = rootState.auth.user.walletAmount;
    const totalAmountToPay = rootGetters['cart/getTotalAmount'];

    if(walletAmount >= totalAmountToPay && totalAmountToPay > 0) {
      return configHelper.paymentMethods.wallet;
    }

    return getters.getSelectedPaymentMethod;
  },
  getSelectedCreditCardDetails: state => state.selectedCreditCardDetails,
  getSavedCreditCardDetails: state => state.savedCreditCardDetails,
  getSelectedCreditCardAlias: state => state.selectedCreditCardAlias,
  getSaveNewCreditCard: state => state.saveNewCreditCard,

  isMinimumOrderAmountReached: (state, getters, rootState, rootGetters) => {
    const deliveryContactId = state.selectedDeliveryContactId;
    if(deliveryContactId === null) {
      return false;
    }

    const zoneId = state.deliveryContacts.find(c => c.id === deliveryContactId)?.zoneId || 1;

    return configHelper.isMinimumOrderAmountReached(rootGetters['cart/getTotalAmount'], zoneId);
  },

  getSelectedBags: state => state.bags,
  getSelectedBagById: findByKey('bags', 'id'),

  // Due to the complexity of the bag system there can be bags that have a quantity of 0.
  // This should be filtered before sending to the backend.
  getFilteredSelectedBags: state => state.bags.filter(b => b.quantity !== 0),

  hasInitializedPayment: state => state.paymentInitResponse !== null,
  getPaymentInitResponse: state => state.paymentInitResponse,

  // Promo code
  getPromoCode: state => state.promoCode,
  getIsApplyingPromoCode: state => state.isApplyingPromoCode,
  getIsRemovingPromoCode: state => state.isRemovingPromoCode,

  // Confirmation
  getShowConfirmationModal: state => state.showConfirmationModal,
  getConfirmButtonLoading: state => state.confirmButtonLoading,
}

export const actions = {
  async initializePaymentForExistingOrder({commit, getters}, {orderId, gaClientId, gaSessionId}) {
    let trackingParams = {
      gaClientId: gaClientId,
      gaSessionId: gaSessionId
    }

    await this.$paymentRepository.initializePaymentForExistingOrder(
      orderId,
      getters.getSelectedPaymentMethod,
      getters.getSelectedCreditCardAlias,
      getters.getSaveNewCreditCard,
      trackingParams
    ).then((res) => {
      commit('setPaymentInitResponse', res.data);
    })
  },

  async initializePaymentForCart({commit, getters, rootGetters}) {
    // Prevent triggering twice.
    if(getters.getConfirmButtonLoading) {
      return false;
    }

    let trackingParams = {
      gaClientId: rootGetters["app/getGaClientId"],
      gaSessionId: rootGetters["app/getGaSessionId"],
    }

    commit('setConfirmButtonLoading', true);

    await this.$paymentRepository.initializePaymentForCart(
      getters.getSelectedPaymentMethodToSend,
      {
        deliveryContactId: getters.getSelectedDeliveryContactId,
        deliveryTimeSlotId: getters.getSelectedTimeslotId,
        packageOptions: getters.getFilteredSelectedBags,
        excludedCampaigns: rootGetters['cart/getExcludedCampaigns'],
        promoCode: rootGetters['cart/getPromoCodeIfValid'],
        cardAlias: getters.getSelectedCreditCardDetails?.alias,
        saveCard: getters.getSaveNewCreditCard,
        trackingParams: trackingParams
      }
    ).then((res) => {
      commit('setPaymentInitResponse', res.data);
    }).finally(() => {
      commit('setConfirmButtonLoading', false);
    });
  },

  applyPromoCode({dispatch, commit}, promoCode) {
    commit('setIsApplyingPromoCode', true);
    commit('setPromoCode', promoCode);
    dispatch('cart/fetchCart', null, {root: true}).finally(() => {
      commit('setIsApplyingPromoCode', false);
    })
  },

  removeAppliedPromoCode({dispatch, commit}) {
    commit('setIsRemovingPromoCode', true);
    commit('setPromoCode', null);
    dispatch('cart/fetchCart', null, {root: true}).finally(() => {
      commit('setIsRemovingPromoCode', false);
    });
  },


  fetchDeliveryContacts({commit}) {
    return this.$deliveryContactRepository.getContacts().then((res) => {
      commit('setDeliveryContacts', res.data);
    })
  },

  fetchTimeslots({commit}, zone) {
    return this.$deliveryTimeslotRepository.getTimeslots(zone).then((res) => {
      commit('setTimeslots', res.data);
    });
  },

  fetchSavedCreditCards({commit}) {
    return this
      .$paymentRepository
      .saferpay
      .fetchSavedCards()
      .then(res => {
        commit('setSavedCreditCardDetails', res.data);
      });
  },

  fetchUpcomingDeliveriesForContact({commit}, deliveryContactId) {
    return this.$deliveryRepository
      .getUpcomingDeliveriesByDeliveryContact(deliveryContactId)
      .then((res) => {
        let timeslotIds = res.data.flatMap(delivery => {
          return delivery.timeSlot.id;
        })

      commit('setTimeslotIdsWithOrders', timeslotIds);
    });
  }
}
