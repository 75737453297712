
import ProductCard from "@/layouts/shop/components/products/ProductCard";
import Banner from "@/layouts/shop/components/banners/Banner";
import {mapGetters} from "vuex";
import {FILTER_TYPE_ALLERGEN, FILTER_TYPE_LABEL} from "@/store/filters";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner.vue";
import OrderInfoCard from "@/layouts/shop/components/account/orders/single/OrderInfoCard.vue";
import ProductInfoCard from "@/layouts/shop/components/products/ProductPage/ProductInfoCard/ProductInfoCard.vue";
import ShopCard from "@/layouts/shop/components/cards/ShopCard.vue";

export default {
  name: 'ProductGrid',
  components: {ShopCard, ProductInfoCard, OrderInfoCard, LoadingSpinner, ProductCard, Banner},
  created() {
    this.mapBanners();
    this.mapPromotedProduct();
    this.mapProducts();
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    products: {
      type: [Array, String]
    },
    recipes: {
      type: [Array, String]
    },
    queryId: {
      type: String,
      default: null
    },
    /**
     * Will be used to map the products from the store, instead of passing them directly as a prop to
     * not have JSON related errors from BlogPostTextRender when theres a single tick in any of the product
     * properties.
     *
     * REQUIRED when prop "products" is not set
     */
    blogProductIds: {
      type: Array,
    },


    // When set to true, is optimized for the blog.
    forBlog: {
      type: Boolean,
      default: false
    },

    // When set to true, the users filters are not applied here
    // Should be the Case for the main page and the blog
    ignoreFilters: {
      type: Boolean,
      default: false
    },


    // The maximum number of secondary banners displayed
    secondaryBannerLimit: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      productItems: [],
      bannerItems: [],
    }
  },
  watch: {
    products: function () {
      this.productItems = [];
      this.mapPromotedProduct();
      this.mapProducts();
    }
  },
  methods: {
    getOrderStyleByProductIndex(index) {
      const order = (index + 1) * 10;
      return `order: ${order};`
    },

    mapProducts() {
      // Set products passed as prop.
      // Ignore the products that are already in the final array.
      // This can be, when a product has been added already by a product banner.
      if (this.products !== undefined) {
        const productIdsAlreadyAdded = this.productItems.flatMap(p => p.id);

        this.products.forEach(product => {
          if(productIdsAlreadyAdded.includes(product.id)) {
            return false;
          }
          this.productItems.push(product);
        });

        return;
      }

      // Products passed by Ids (Used on Blog).
      // Resolving the product objects here.
      this.productItems = this.$store.getters['blog/getReferencedProducts'].filter(p => {
        return this.blogProductIds.includes(p.id);
      })
    },

    mapBanners() {
      let secondaryBanners = this.getSecondaryBanners;

      // Display recipes as banners
      if (Array.isArray(this.recipes) && this.recipes.length) {
        secondaryBanners = secondaryBanners.concat(this.recipes);
      }

      // Set Main Banners
      if (this.getMainBanner) {
        this.bannerItems.push({placement: "Main", banner: this.getMainBanner})
      }

      // Set Secondary Banners
      if (!secondaryBanners.length) {
        return false;
      }

      secondaryBanners
        .filter(banner => banner.bannerable_type !== "Product")
        .slice(0, this.secondaryBannerLimit)
        .forEach((banner, index) => {
          this.bannerItems.push({
            placement: "Secondary",
            banner: banner,
            domId: "secondaryBanner" + (index + 1)
          });
        });
    },


    /**
     * Adds the first product that has been set through the banner system.
     * This product will be highlighted in a special way and appear bigger.
     *
     * Note: Only one product can be set like that.
     */
    mapPromotedProduct() {
      let firstProductBanner = this.getSecondaryBanners.find(banner => banner.bannerable_type === "Product" && "additionalBannerableData" in banner);

      if (firstProductBanner) {
        let productData = firstProductBanner.additionalBannerableData;
        productData.promoted = true;
        productData.outerClasses = "row-span-2 md:col-span-2";

        this.productItems.push(productData);
      }
    }
  },
  computed: {
    /**
     * Takes all products, ignores the products that are filtered out
     * through tags, and returns them, ready for display.
     *
     * @returns {*[]}
     */
    getMappedProducts() {
      let products = this.productItems;

      if (!this.ignoreFilters) {
        // Label Filters
        if (this.getLabelFilters.length) {
          // Returns only products that match all filters
          products = products
            .filter(product => {
              let productTags = product.tags.flatMap(t => t.id);
              const flattenActiveTags = this.getLabelFilters.flatMap(filterTag => filterTag.id);

              return flattenActiveTags.every(tagId => productTags.includes(tagId));
            })
        }

        // Allergen Filters (Inverse Logic)
        if (this.getAllergenFilters.length) {
          // Returns only products that match all filters
          products = products
            .filter(product => {
              let productAllergens = product.tags.filter(t => t.type === "allergen").flatMap(t => t.id);
              const filteredAllergenIds = this.getAllergenFilters.flatMap(filterTag => filterTag.id);

              return filteredAllergenIds.every(tagId => !productAllergens.includes(tagId));
            })
        }
      }

      return products;
    },
    getMappedBanners() {
      return this.bannerItems;
    },
    ...mapGetters('filters', [
        'getActiveFiltersWithProperties'
      ]),
    getPageIdentifier() {
      return this.$pageDataHelper.getPageIdentifierByRoute(this.$route);
    },
    getBanners() {
      return this.$store.getters['pageData/getBannersForPage'](this.getPageIdentifier);
    },
    getMainBanner() {
      return this.getBanners.find(b => b.placement === "Main");
    },
    getSecondaryBanners() {
      return this.getBanners.filter(b => b.placement === "Secondary");
    },
    getLabelFilters() {
      return this.getActiveFiltersWithProperties.filter(f => f.type === FILTER_TYPE_LABEL);
    },
    getAllergenFilters() {
      return this.getActiveFiltersWithProperties.filter(f => f.type === FILTER_TYPE_ALLERGEN);
    },
    getGridClasses() {
      if (this.forBlog) {
        return "grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 gap-2 md:gap-4";
      }
      return "grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 4xl:grid-cols-5 gap-1.5 md:gap-4 lg:gap-8 xl:gap-4 2xl:gap-8";
    }
  }
}
